import * as React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Add Contact Form
// Add Phone Number
// Add Contact Hours
// Email Address (Mailto)

const ContactContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  & div,
  form {
    margin: 20px;
  }

  & h3 {
    font-size: 1.8em;
    color: #006666;
  }

  & p {
    margin-top: 20px;
    font-size: 1.4em;
    color: #aaa;
  }
`

const PhoneNumber = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
`

const EmailAddress = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
`

const ContactForm = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 80%;
  max-width: 600px;
  & * {
    padding: 10px;
    margin: 10px;
  }
  & *:focus {
    outline: none;
    border: 2px solid #006666;
  }
  & input {
    font-size: 1.4em;
    border: 2px solid #888;
  }
  & textarea {
    font-size: 1.4em;
    border: 2px solid #888;
  }
  & button {
    font-size: 1.2em;
    border: 2px solid #006666;
    background: #006666;
    color: #ddd;
    transition: background 0.2s, color 0.2s;
  }
  & button:hover {
    background: white;
    color: #006666;
    cursor: pointer;
  }
`

export default function ContactPage({ data }) {
  const phone = data.markdownRemark.frontmatter.phone
  const email = data.markdownRemark.frontmatter.email
  return (
    <Layout header="Contact">
      <SEO
        title="Contact"
        description="Get in touch with Detail Designs for your costume design commissions."
        pathname="/contact"
      />
      <ContactContainer>
        <PhoneNumber>
          <h3>Phone Number</h3>
          <p>{phone}</p>
        </PhoneNumber>
        <EmailAddress>
          <h3>Email Address</h3>
          <p>{email}</p>
        </EmailAddress>
        <ContactForm
          method="post"
          netlify-honeypot="bot-field"
          data-netlify="true"
          name="Contact Me"
        >
          <h3>Contact Me</h3>
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="Contact Me" />
          <input type="text" placeholder="Full Name" name="Name" />
          <input type="email" placeholder="Email Address" name="Email" />
          <textarea placeholder="Message..." rows="5" name="Message" />
          <button type="submit">Send Message</button>
        </ContactForm>
      </ContactContainer>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPageQuery {
    markdownRemark(frontmatter: { type: { eq: "contact-page" } }) {
      frontmatter {
        phone
        email
      }
    }
  }
`
